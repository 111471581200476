<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 md10>
      <material-card :title="title" icon="mdi-bank-transfer-out">
        <v-card-text class="page-info">
          <v-form id="formCheckin">
            <v-container class="page-info">
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Date/Fecha:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <v-text-field
                    solo
                    class="right-input"
                    readonly
                    v-model="form.checkin.date"
                    append-outer-icon="far fa-calendar-alt"
                    :disabled="disabled.checkIn"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" md="1"> </v-col>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Company/Compañia:"
                    :required="validationFields.checkin.idCompany.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-if="showCompanyCombo"
                    solo
                    :error-messages="companyErrors"
                    :items="controls.companies"
                    item-text="companyName"
                    item-value="idCompany"
                    v-model="form.checkin.idCompany"
                    @input="$v.form.checkin.idCompany.$touch()"
                    @blur="$v.form.checkin.idCompany.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Pick Up/Orden:"
                    :required="validationFields.checkin.so.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-dialog
                    v-model="controls.ordersDialog"
                    v-if="type == 'checkin'"
                    max-width="500"
                  >
                    <orders
                      v-model="form.checkin.orders"
                      :control-search="controlsSearch"
                      :on-close-dialog="closeOrders"
                    ></orders>
                  </v-dialog>
                  <v-text-field
                    solo
                    @click.stop="controls.ordersDialog = true"
                    class="right-input"
                    v-model="form.checkin.so"
                    maxlength="30"
                    v-if="type == 'checkin'"
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-if="type != 'checkin'"
                    solo
                    class="right-input"
                    v-model="form.checkin.so"
                    :error-messages="soErrors"
                    maxlength="20"
                    @input="$v.form.checkin.so.$touch()"
                    @blur="$v.form.checkin.so.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Customer/Cliente:"
                    :required="validationFields.checkin.destination.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model="form.checkin.destination"
                    :error-messages="destinationErrors"
                    maxlength="30"
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.destination.$touch()"
                    @blur="$v.form.checkin.destination.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Operator name/Operador:"
                    :required="validationFields.checkin.driverName.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model="form.checkin.driverName"
                    :error-messages="operatorErrors"
                    maxlength="30"
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.driverName.$touch()"
                    @blur="$v.form.checkin.driverName.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Carrier/Transportista:"
                    :required="validationFields.checkin.carrierName.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model="form.checkin.carrierName"
                    :error-messages="carrierErrors"
                    maxlength="30"
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.carrierName.$touch()"
                    @blur="$v.form.checkin.carrierName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Driver License/Licencia:"
                    :required="validationFields.checkin.driverLicense.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model="form.checkin.driverLicense"
                    :error-messages="driverLicenseErrors"
                    maxlength="10"
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.driverLicense.$touch()"
                    @blur="$v.form.checkin.driverLicense.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Truck Plate/Placas Tractor:"
                    :required="validationFields.checkin.truckPlate.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model="form.checkin.truckPlate"
                    :error-messages="truckPlateErrors"
                    maxlength="10"
                    label=""
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.truckPlate.$touch()"
                    @blur="$v.form.checkin.truckPlate.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Phone/Teléfono:"
                    :required="validationFields.checkin.driverPhone.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-on:keypress="isNumber"
                    v-model="form.checkin.driverPhone"
                    :error-messages="phoneErrors"
                    maxlength="10"
                    :counter="10"
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.driverPhone.$touch()"
                    @blur="$v.form.checkin.driverPhone.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <required-mark
                    text="Trailer Plate/Placas Trailer:"
                    :required="validationFields.checkin.trailerPlate.required"
                  ></required-mark>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model="form.checkin.trailerPlate"
                    :error-messages="trailerPlateErrors"
                    maxlength="10"
                    :disabled="disabled.checkIn"
                    @input="$v.form.checkin.trailerPlate.$touch()"
                    @blur="$v.form.checkin.trailerPlate.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8" xs="12">
                  <required-mark
                    text="Trailer Distribution / Distribución:"
                    :required="validationFields.checkin.trailerPlate.required"
                  ></required-mark>
                </v-col>
              </v-row>
              <v-row style="padding-bottom: 20px">
                <v-col xs="12" md="9">
                  <vue-select-image
                    ref="distribution"
                    :dataImages="controls.distributions"
                    h="160px"
                    w="160px"
                    :useLabel="true"
                    @onselectimage="onSelectDistribution"
                    :selectedImages="controls.initialDistribution"
                  >
                  </vue-select-image>
                </v-col>
                <v-col md="2">
                  <v-row>
                    <required-mark text="Other:"></required-mark>
                  </v-row>
                  <v-row>
                    <v-text-field
                      solo
                      class="right-input"
                      v-model="form.checkin.otherDistribution"
                      maxlength="10"
                      :disabled="disabled.checkIn"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="type != 'new'">
                <v-flex xs12 md2 offset-md3>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <required-mark
                        text="Driver Sign / Firma Chofer:"
                        :required="
                          validationFields.checkin.trailerPlate.required
                        "
                      ></required-mark>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 md3 offset-md2>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <required-mark
                        text="ID / Licencia:"
                        :required="
                          validationFields.checkin.trailerPlate.required
                        "
                      ></required-mark>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-row>
              <v-row v-if="type != 'new'">
                <v-col xs="12" md="3" offset-md="2">
                  <v-card
                    class="signature"
                    v-bind:class="{ 'border-error': controls.signRequired }"
                  >
                    <VueSignaturePad
                      class="right-input"
                      ref="signaturePad"
                      :disabled="type != 'checkin'"
                      :options="{ onBegin, onEnd }"
                    />
                  </v-card>
                </v-col>
                <v-col xs="12" md="3" offset-md="1">
                  <v-card class="license">
                    <img
                      height="154"
                      width="300"
                      v-bind:src="form.checkin.license"
                      v-bind:class="{ 'border-error': controls.scanRequired }"
                    />
                    <v-btn
                      small
                      color="blue"
                      dark
                      @click="scanLicense()"
                      :loading="controls.loadingScanner"
                      v-if="type == 'checkin'"
                    >
                      <v-icon>mdi-scanner</v-icon>
                      Scan/Escanear
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <warehouse-policies></warehouse-policies>
          <div v-if="!controls.isCheckIn">
            <div>
              <title-section
                >FOR EXCLUSIVE USE OF THE WAREHOUSE / PARA USO EXCLUSIVO DEL
                ALMACEN</title-section
              >
            </div>
            <subtitle-section>SHIPPING / MOSTRADOR</subtitle-section>
            <v-form v-if="type != 'checkin'" id="formClerk">
              <v-container class="page-info">
                <v-row wrap>
                  <v-col cols="12" md="2">
                    <required-mark
                      text="Check In/Entrada:"
                      :required="validationFields.clerk.checkIn.required"
                    ></required-mark>
                  </v-col>
                  <v-col cols="12" md="3">
                    <vue-ctk-date-time-picker
                      solo
                      class="right-input"
                      v-model="form.clerk.checkIn"
                      formatted="YYYY/MM/DD hh:mm a"
                      :no-value-to-custom-elem="true"
                      :disabled="disabled.clerk"
                    >
                      <v-text-field
                        solo
                        class="right-input"
                        append-outer-icon="far fa-calendar-alt"
                        v-model="form.clerk.checkIn"
                        :error-messages="clerkCheckInErrors"
                        maxlength="30"
                        :disabled="disabled.clerk"
                        @input="$v.form.clerk.checkIn.$touch()"
                        @blur="$v.form.clerk.checkIn.$touch()"
                        readonly
                      ></v-text-field>
                    </vue-ctk-date-time-picker>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" md="2">
                    <required-mark
                      text="Door Assignment/Asignación Puerta:"
                      :required="validationFields.clerk.doorAssignment.required"
                    ></required-mark>
                  </v-col>
                  <v-col cols="12" md="3">
                    <vue-ctk-date-time-picker
                      solo
                      class="right-input"
                      v-model="form.clerk.doorAssignment"
                      formatted="YYYY/MM/DD hh:mm a"
                      :disabled="disabled.clerk"
                      :no-value-to-custom-elem="true"
                    >
                      <v-text-field
                        solo
                        class="right-input"
                        append-outer-icon="far fa-calendar-alt"
                        v-model="form.clerk.doorAssignment"
                        :error-messages="doorAssignmentErrors"
                        :disabled="disabled.clerk"
                        @input="$v.form.clerk.doorAssignment.$touch()"
                        @blur="$v.form.clerk.doorAssignment.$touch()"
                        readonly
                      ></v-text-field>
                    </vue-ctk-date-time-picker>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" md="2">
                    <required-mark
                      text="Door/Puerta:"
                      :required="validationFields.clerk.doorID.required"
                    ></required-mark>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      solo
                      class="right-input"
                      :items="controls.doors"
                      outline
                      append-outer-icon="fas fa-door-open"
                      v-model="form.clerk.doorID"
                      item-text="name"
                      item-value="id"
                      :disabled="disabled.clerk"
                      :error-messages="doorErrors"
                      @input="$v.form.clerk.doorID.$touch()"
                      @blur="$v.form.clerk.doorID.$touch()"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="1" v-if="form.clerk.doorID != null">
                    <v-btn
                      icon
                      text
                      color="red lighten-2"
                      class="v-input__append-outer"
                      :disabled="disabled.clerk"
                    >
                      <v-icon @click="removeDoor()" title="Remove door">
                        fas fa-minus-square
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" md="2">
                    <v-subheader class="label-left"
                      >Forklift Driver/Montacarguista:</v-subheader
                    >
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      solo
                      class="right-input"
                      v-model="form.clerk.forkLiftDriver"
                      :error-messages="forkliftDriverErrors"
                      maxlength="30"
                      :disabled="disabled.clerk"
                      @input="$v.form.clerk.forkLiftDriver.$touch()"
                      @blur="$v.form.clerk.forkLiftDriver.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <products
                      v-model="form.clerk.products"
                      :list="controls.commodities"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <subtitle-section
              >FORKLIFT OPERATOR / MONTACARGUISTA</subtitle-section
            >
            <v-form v-if="form.clerk.doorAssignment && form.status >= 3">
              <v-container class="page-info">
                <v-row>
                  <v-col xs="2" md="2">
                    <required-mark
                      text="Start Unloading/Inicio Descarga:"
                      :required="
                        validationFields.forkLift.startForkLift.required
                      "
                    ></required-mark>
                  </v-col>
                  <v-col xs="3" md="2">
                    <vue-ctk-date-time-picker
                      solo
                      class="right-input"
                      v-model="form.forkLift.startForkLift"
                      formatted="YYYY/MM/DD hh:mm a"
                      :no-value-to-custom-elem="true"
                      :disabled="disabled.forkLift"
                    >
                      <v-text-field
                        solo
                        class="right-input"
                        append-outer-icon="far fa-calendar-alt"
                        v-model="form.forkLift.startForkLift"
                        :error-messages="startForkLiftErrors"
                        maxlength="30"
                        @input="$v.form.forkLift.startForkLift.$touch()"
                        @blur="$v.form.forkLift.startForkLift.$touch()"
                        readonly
                        :disabled="disabled.forkLift"
                      ></v-text-field>
                    </vue-ctk-date-time-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="2" xs="2">
                    <required-mark
                      text="Finish Unloading/Fin Descarga:"
                      :required="
                        validationFields.forkLift.finishForkLift.required
                      "
                    ></required-mark>
                  </v-col>
                  <v-col md="2" xs="3">
                    <vue-ctk-date-time-picker
                      solo
                      class="right-input"
                      v-model="form.forkLift.finishForkLift"
                      formatted="YYYY/MM/DD hh:mm a"
                      :no-value-to-custom-elem="true"
                      :disabled="disabled.forkLift"
                    >
                      <v-text-field
                        solo
                        class="right-input"
                        append-outer-icon="far fa-calendar-alt"
                        v-model="form.forkLift.finishForkLift"
                        :error-messages="finishForkLiftErrors"
                        @input="$v.form.forkLift.finishForkLift.$touch()"
                        @blur="$v.form.forkLift.finishForkLift.$touch()"
                        readonly
                        :disabled="disabled.forkLift"
                      ></v-text-field>
                    </vue-ctk-date-time-picker>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col xs="12" md="3">
                    <div class="text-heading-6">
                      Trailer Inspection/Inspection Trailer
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Clean Trailer/Caja Limpia:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.cleanTrailer"
                      :label="form.forkLift.cleanTrailer ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="5">
                    <v-text-field
                      outline
                      label="Comments/Comentarios"
                      v-model="form.forkLift.cleanTrailerComments"
                      maxlength="100"
                      :disabled="disabled.forkLift"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Odor Free/Libre de Olores:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.odorFree"
                      :label="form.forkLift.odorFree ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="5">
                    <v-text-field
                      outline
                      label="Comments/Comentarios"
                      v-model="form.forkLift.odorFreeComments"
                      maxlength="100"
                      :disabled="disabled.forkLift"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Inside Intact/Paredes y Pisos Intactos:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.insideIntact"
                      :label="form.forkLift.insideIntact ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="5">
                    <v-text-field
                      outline
                      label="Comments/Comentarios"
                      v-model="form.forkLift.insideIntactComments"
                      maxlength="100"
                      :disabled="disabled.forkLift"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Reefer Working/Thermo Prendido:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.reeferWorking"
                      :label="form.forkLift.reeferWorking ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="5">
                    <v-text-field
                      outline
                      label="Comments/Comentarios"
                      v-model="form.forkLift.reeferWorkingComments"
                      maxlength="100"
                      :disabled="disabled.forkLift"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Tipped Pallets/Tarimas Ladeadas:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.tippedPallets"
                      :label="form.forkLift.tippedPallets ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="2">
                    <v-text-field
                      outline
                      class="right-input"
                      v-model="form.forkLift.tippedPalletsQty"
                      type="number"
                      label="Qty"
                      min="0"
                      max="1000"
                      :disabled="
                        disabled.forkLift || !form.forkLift.tippedPallets
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Restack/Reentarimado:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.restack"
                      :label="form.forkLift.restack ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="2">
                    <v-text-field
                      outline
                      class="right-input"
                      v-model="form.forkLift.restackQty"
                      type="number"
                      label="Qty"
                      min="0"
                      max="1000"
                      :disabled="disabled.forkLift || !form.forkLift.restack"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col xs="12" md="3">
                    <v-subheader class="label-left"
                      >Temp Recorder/Termometro:</v-subheader
                    >
                  </v-col>
                  <v-col xs="12" md="1">
                    <v-switch
                      v-model="form.forkLift.tempRecorder"
                      :label="form.forkLift.tempRecorder ? 'Si' : 'No'"
                      :disabled="disabled.forkLift"
                    ></v-switch>
                  </v-col>
                  <v-col xs="12" md="5">
                    <v-text-field
                      outline
                      label="Comments/Comentarios"
                      v-model="form.forkLift.tempRecorderComments"
                      maxlength="100"
                      :disabled="disabled.forkLift"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
          <div v-if="type != 'checkin'">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-subheader class="label-left"
                      >Comments/Comentarios:</v-subheader
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      v-model="form.general.comments"
                      no-resize
                      maxlength="1000"
                      :disabled="disabled.general"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue"
            dark
            @click="save"
            :loading="isLoading"
            v-if="form.status != 6"
            >Save/Grabar
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            color="blue"
            dark
            @click="updateClose"
            :loading="isLoading"
            v-if="form.status == 6"
            >Update
            <v-icon right>fas fa-edit</v-icon>
          </v-btn>
          <v-btn
            color="blue"
            dark
            @click="close"
            :loading="isLoading"
            v-if="showButtonClose"
            >{{ closeButton }}
            <v-icon right>mdi-close-circle-outline</v-icon>
          </v-btn>
          <v-btn
            color="orange"
            @click="reactive"
            :loading="isLoading"
            dark
            v-if="form.status == 6"
          >
            Reactivate
            <v-icon right>fas fa-redo</v-icon>
          </v-btn>
          <v-btn
            color="green"
            @click="download"
            :loading="isLoading"
            dark
            v-if="type != 'checkin' && form.status > 0"
            >Download
            <v-icon right>fas fa-file-download</v-icon>
          </v-btn>
          <v-btn
            color="amber"
            @click="hold"
            dark
            v-if="type != 'checkin' && form.status > 0 && form.status != 6"
            >Hold
            <v-icon right>fas fa-stop-circle</v-icon>
          </v-btn>
          <v-btn color="red" dark @click="cancel"
            >Cancel/Cancelar
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import VueSelectImage from "vue-select-image";

import WarehousePolicies from "../../components/WarehousePolicies";

import Products from "../../components/Products";
import TitleSection from "../../components/TitleSection";
import TempIndicator from "../../components/TempIndicator";
import SubtitleSection from "../../components/SubtitleSection";
import outbound from "../../services/api/outbound";
import devices from "../../services/devices";
import common from "../../services/api/common";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import companies from "../../services/api/companies";

const requiredIfCheckin = requiredIf(function () {
  return this.requiredCheckin;
});
const validationCheckin = () => {
  return {
    checkin: {
      so: {
        required,
      },
      idCompany: {
        required,
      },
      driverName: {
        required: requiredIfCheckin,
      },
      driverLicense: {
        required: requiredIfCheckin,
      },
      driverPhone: {
        required: requiredIfCheckin,
        minLength: minLength(10),
      },
      destination: { required: false },
      carrierName: { required: false },
      truckPlate: {
        required: false,
      },
      trailerPlate: {
        required: requiredIfCheckin,
      },
      trailerDistributionID: {
        required: false,
      },
    },
  };
};

const requiredIfClerk = requiredIf(function () {
  return this.requiredClerk;
});

const validationClerk = () => {
  return {
    clerk: {
      checkIn: {
        required: requiredIfClerk,
      },
      doorAssignment: {
        required: false,
      },
      doorID: {
        required: false,
      },
      forkLiftDriver: {
        required: false,
      },
      products: {
        required: false,
      },
    },
  };
};

const requiredIfForkLift = requiredIf(function () {
  return this.requiredForkLift;
});
const validationForkLift = () => {
  return {
    forkLift: {
      startForkLift: {
        required: requiredIfForkLift,
      },
      finishForkLift: {
        required: requiredIfForkLift,
      },
    },
  };
};

export default {
  name: "driverInbound",
  data() {
    return {
      form: {
        id: null,
        status: -1,
        checkin: {
          orders: null,
          date: null,
          so: null,
          idCompany: null,
          companyName: "",
          driverName: null,
          driverLicense: null,
          driverPhone: null,
          destination: null,
          carrierName: null,
          truckPlate: null,
          trailerPlate: null,
          trailerDistributionID: null,
          otherDistribution: null,
          license: null,
        },
        clerk: {
          checkIn: null,
          doorAssignment: null,
          doorID: null,
          forkLiftDriver: null,
          products: [],
        },
        forkLift: {
          startForkLift: null,
          finishForkLift: null,
          cleanTrailer: false,
          cleanTrailerComments: "",
          odorFree: false,
          odorFreeComments: "",
          insideIntact: false,
          insideIntactComments: "",
          reeferWorking: false,
          reeferWorkingComments: "",
          tippedPallets: false,
          tippedPalletsQty: 0,
          restack: false,
          restackQty: 0,
          tempRecorder: false,
          tempRecorderComments: "",
          temperature: 7,
        },
        general: {
          comments: "",
        },
      },
      controls: {
        qa: false,
        loadingScanner: false,
        doors: [],
        commodities: [],
        isCheckIn: true,
        signatureImage: "",
        temperatureRanges: [],
        distributions: [
          {
            id: "1",
            src: "/img/distributions/block.png",
            alt: "Sq. Block",
          },
          {
            id: "2",
            src: "/img/distributions/sidebyside.png",
            alt: "Side by Side",
          },
          {
            id: "3",
            src: "/img/distributions/singledoublesingle.png",
            alt: "Single Double Single",
          },
          {
            id: "4",
            src: "/img/distributions/eleven.png",
            alt: "Eleven",
          },
        ],
        initialDistribution: [],
        entries: [],
        search: null,
        scanRequired: false,
        signRequired: false,
        pictureRequired: false,
        picture: null,
        ordersDialog: false,
      },
      disabled: {
        checkIn: false,
        clerk: false,
        forkLift: false,
        general: false,
      },
      isLoading: false,
      showCompanyCombo: false,
    };
  },
  props: {
    type: {
      type: String,
    },
  },
  validations() {
    const checkin = validationCheckin(this);
    const clerk = validationClerk(this);
    const forkLift = validationForkLift(this);
    const formValidation = {
      form: {
        ...checkin,
        ...clerk,
        ...forkLift,
      },
    };
    return formValidation;
  },
  mounted() {
    if (this.type != "checkin" && this.type != "new") {
      if (this.$refs.signaturePad != null) {
        this.$refs.signaturePad.$refs.signaturePadCanvas.classList.add(
          "disabled"
        );
        const c = document.querySelector("canvas");
        c.style.pointerEvents = "none";
      }
    }
  },
  async created() {
    this.getAllCompanies("");
    common.getCommodity().then((data) => {
      this.controls.commodities = data.body;
    });
    this.controls.isCheckIn = this.type == "checkin";
    if (this.type != "edit") {
      this.form.checkin.date = moment().format("MM/DD/YYYY hh:mm:ss a");
    } else {
      const data = (await outbound.get(this.$route.params.id)).data;
      if (data.checkin.signature) {
        this.controls.signatureImage = data.checkin.signature;
        this.$refs.signaturePad.fromDataURL(data.checkin.signature);
      }
      if (data.checkin.trailerDistributionID) {
        const distribution = this.controls.distributions.find(
          (p) => p.id == data.checkin.trailerDistributionID
        );
        this.controls.initialDistribution.push(distribution);
        this.$refs.distribution.setInitialSelection();
      }
      this.form.id = data.id;
      this.form.checkin = data.checkin;
      //     this.form.checkin.date = moment(this.form.checkin.date).format('MM/DD/YYYY hh:mm:ss a');
      this.form.status = data.status;
      this.form.general = data.general;
      this.controls.qa = data.qa;
      if (data.clerk) {
        this.form.clerk = data.clerk;
      }
      if (data.forkLift) {
        this.form.forkLift = data.forkLift;
        common.getCommodityTemperature().then((data) => {
          this.controls.temperatureRanges = data.body;
        });
      }
    }
    this.fetchData();
    this.disableElements();

    const now = moment().format("MM/DD/YYYY hh:mm a");
    if (this.type != "checkin" && this.type != "new") {
      if (this.form.clerk.checkIn == null) this.form.clerk.checkIn = now;
      if (this.form.clerk.doorAssignment == null)
        this.form.clerk.doorAssignment = now;
    }
    if (this.requiredForkLift) {
      if (this.form.clerk.startForkLift == null)
        this.form.forkLift.startForkLift = now;
      if (this.form.clerk.finishForkLift == null)
        this.form.forkLift.finishForkLift = now;
    }
  },
  components: {
    WarehousePolicies,
    TitleSection,
    SubtitleSection,
    VueCtkDateTimePicker,
    TempIndicator,
    VueSelectImage,
    Products,
    Camera: () => import("@/components/Camera"),
    MaterialCard: () => import("@/components/base/MaterialCard"),
    RequiredMark: () => import("@/components/RequiredMark"),
    Orders: () => import("@/components/Orders"),
  },
  methods: {
    closeOrders(data) {
      this.controls.ordersDialog = false;
      this.form.checkin.so = data.map((d) => d.order).join(", ");
      this.form.checkin.orders = data.map((d) => d.order);
    },
    async controlsSearch(find) {
      const data = (await outbound.getSearch(find)).body;
      return data;
    },
    async fetchData() {
      common.getAvailableDoor(this.form.clerk.doorID).then((data) => {
        const doors = data.body.sort((a, b) =>
          parseInt(a.name) > parseInt(b.name) ? 1 : -1
        );
        this.controls.doors = doors;
      });
    },
    disableElements() {
      if (this.form.status === 6) {
        this.disabled.clerk = true;
        this.disabled.forkLift = true;
        this.disabled.checkIn = true;
        this.disabled.general = true;
      } else {
        /*if (this.user.role == 'Shipping') {
                                          if (this.form.status !== 3) {
                                            this.disabled.forkLift = true
                                          }
                                          this.disabled.checkIn = true
                                          this.disabled.clerk = true
                                        }*/
      }
    },
    async prefillSelected(model) {
      this.loading = true;
      const data = (await outbound.getPrefill(model)).data;
      if (data) {
        this.form.checkin = data.checkin;
        if (data.checkin.trailerDistributionID) {
          const distribution = this.controls.distributions.find(
            (p) => p.id == data.checkin.trailerDistributionID
          );
          this.controls.initialDistribution.push(distribution);
          this.$refs.distribution.setInitialSelection();
        }
      }
      this.loading = false;
    },
    addProduct() {
      if (
        this.controls.productID != "" &&
        this.controls.productID != undefined
      ) {
        const productID = this.controls.productID;
        const prod = this.controls.commodities.find((c) => c.id == productID);
        this.form.clerk.products.push(prod);
        this.controls.productID = "";
      }
    },
    removeProduct(item) {
      const filtered = this.form.clerk.products.filter(
        (e) => e.name != item.name
      );
      this.form.clerk.products = filtered;
    },
    incrementTemp(item) {
      item.temperature = parseInt(item.temperature, 10) + 1;
    },
    decrementTemp(item) {
      item.temperature = parseInt(item.temperature, 10) - 1;
    },
    onSelectDistribution(data) {
      this.form.checkin.trailerDistributionID = data.id;
    },
    onBegin() {
      this.controls.signRequired = false;
    },
    onEnd() {},
    async saveNew() {
      try {
        //               this.form.checkin.date = new Date(this.form.checkin.date).toISOString()
        await outbound.createPO(this.form);
        this.$toast.success("Created PU/PU Creada");
        this.$router.push({
          name: "dashboard",
        });
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async saveCheckin() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.controls.scanRequired = this.form.checkin.license == null;
      this.controls.signRequired = isEmpty;
      if (
        !isEmpty &&
        this.form.checkin.license != null &&
        (this.form.checkin.otherDistribution ||
          this.form.checkin.trailerDistributionID)
      ) {
        const signature = data.replace("data:image/png;base64,", "");
        this.form.checkin.signature = signature;
        const pureLicense = this.form.checkin.license.replace(
          "data:image/png;base64,",
          ""
        );
        this.form.checkin.license = pureLicense;
        try {
          await outbound.create(this.form.checkin);
          this.$toast.success("Registered/Registrada");
          const routerTarget = this.controls.isCheckIn
            ? "success"
            : "dashboard";
          this.$router.push({
            name: routerTarget,
          });
        } catch (e) {
          this.$toast.error(
            "There was an error saving/Hubo un error al guardar"
          );
        }
      }
    },
    async saveEdit() {
      try {
        const pureLicense =
          this.form.checkin.license != null
            ? this.form.checkin.license.replace("data:image/png;base64,", "")
            : null;
        this.form.checkin.license = pureLicense;
        await outbound.update(this.form);
        const routerTarget = this.controls.isCheckIn ? "home" : "dashboard";
        this.$router.push({
          name: routerTarget,
        });
      } catch (e) {
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          switch (this.type) {
            case "new":
              await this.saveNew();
              break;
            case "checkin":
              await this.saveCheckin();
              break;
            case "edit":
              await this.saveEdit();
              break;
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    async hold() {
      try {
        const pureLicense =
          this.form.checkin.license != null
            ? this.form.checkin.license.replace("data:image/png;base64,", "")
            : null;
        this.form.checkin.license = pureLicense;
        await outbound.hold(this.form);
        this.$toast.success("Hold");
        const routerTarget = "dashboard";
        this.$router.push({
          name: routerTarget,
        });
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async updateClose() {
      try {
        await outbound.updateClose(this.form);
        this.$toast.success("Updated");
        const routerTarget = "historic";
        this.$router.push({
          name: routerTarget,
          query: this.$route.query,
        });
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async close() {
      try {
        this.$router.push({
          name: "closeOutbound",
          params: {
            id: this.form.id,
            order: this.form.checkin.so,
            status: this.form.status,
          },
        });
      } catch (e) {
        this.$toast.error("There was an error saving/Hubo un error al guardar");
        this.isLoading = false;
      }
    },
    cancel() {
      const routerTarget = this.controls.isCheckIn ? "home" : "dashboard";
      this.$router.push({
        name: routerTarget,
      });
    },
    async download() {
      this.loading = true;
      const response = await outbound.download(this.form.id);
      const blob = new Blob([response.body]);
      const link = document.createElement("a");
      link.className = "file";
      link.href = window.URL.createObjectURL(blob);
      link.download = "OutboundCheckIn.pdf";
      document.body.appendChild(link);
      link.click();
      this.loading = false;
    },
    async scanLicense() {
      this.controls.loadingScanner = true;
      devices.scan().then(
        (data) => {
          this.controls.loadingScanner = false;
          if (data.body == "" || data.body == undefined) {
            this.$toast.warning("No data/Sin información");
          } else {
            this.form.checkin.license = data.body;
            this.controls.scanRequired = this.form.checkin.license == null;
          }
        },
        (e) => {
          this.$toast.error("Scanner error/Error en el escaner");
          this.controls.loadingScanner = false;
        }
      );
    },
    async reactive() {
      this.isLoading = true;
      try {
        await outbound.reactive(this.form.id);
        this.$toast.success("Reactivated/Reactivada");
        this.$router.push({
          name: "dashboard",
        });
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      } finally {
        this.isLoading = false;
      }
    },
    onCaptureImage(image) {
      var picture = image.source.replace("data:image/jpeg;base64,", "");
      this.controls.picture = picture;
    },
    getAllCompanies(search) {
      companies.getCompanyCombo(search).then((res) => {
        this.controls.companies = res.body.data;
        this.showCompanyCombo = true;
      });
    },
    removeDoor() {
      this.form.clerk.doorID = null;
      this.form.clerk.doorAssignment = null;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  watch: {
    "controls.search": function (newVal, _oldVal) {
      const items = outbound.getSearch(newVal).then((res) => {
        this.controls.entries = res.body;
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
    validationFields() {
      const checkin = {
        checkin: {
          so: {
            required: true,
          },
          idCompany: {
            required: true,
          },
          driverName: {
            required: this.requiredCheckin,
          },
          driverLicense: {
            required: this.requiredCheckin,
          },
          driverPhone: {
            required: this.requiredCheckin,
          },
          destination: {},
          carrierName: {},
          truckPlate: {
            required: false,
          },
          trailerPlate: {
            required: this.requiredCheckin,
          },
          trailerDistributionID: {
            required: false,
          },
        },
      };

      const clerk = {
        clerk: {
          checkIn: {
            required: this.requiredClerk,
          },
          doorAssignment: {
            required: false,
          },
          doorID: {
            required: false,
          },
          forkLiftDriver: {
            required: false,
          },
          products: {
            required: false,
          },
        },
      };

      const forkLift = {
        forkLift: {
          startForkLift: {
            required: this.requiredForkLift,
          },
          finishForkLift: {
            required: this.requiredForkLift,
          },
        },
      };

      const formValidation = {
        ...checkin,
        ...clerk,
        ...forkLift,
      };
      return formValidation;
    },
    items() {
      return this.controls.entries.map((entry) => {
        return entry;
      });
    },
    closeButton() {
      return this.form.status == 6 ? "Cargos" : "Close/Cerrar";
    },
    availableCommodities() {
      const remaing = this.controls.commodities.filter(
        ({ name }) => !this.form.clerk.products.some((obj) => obj.name == name)
      );
      return remaing;
    },
    showButtonClose() {
      return (
        (this.form.status == 5 || this.form.status == 6) &&
        (this.user.role == "Administrator" || this.user.role == "Shipping")
      );
    },
    requiredCheckin() {
      return this.type == "checkin";
    },
    requiredClerk() {
      return this.form.clerk.doorAssignment != undefined;
    },
    requiredForkLift() {
      return this.form.status === 3;
    },
    title() {
      return this.controls.isCheckIn ? "OUTBOUND CHECKIN" : "OUTBOUND";
    },
    soErrors() {
      const errors = [];
      if (!this.$v.form.checkin.so.$dirty) return errors;
      !this.$v.form.checkin.so.required && errors.push("Required/Requerido");
      return errors;
    },
    operatorErrors() {
      const errors = [];
      if (!this.$v.form.checkin.driverName.$dirty) return errors;
      !this.$v.form.checkin.driverName.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    driverLicenseErrors() {
      const errors = [];
      if (!this.$v.form.checkin.driverLicense.$dirty) return errors;
      !this.$v.form.checkin.driverLicense.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.checkin.driverPhone.$dirty) return errors;
      !this.$v.form.checkin.driverPhone.minLength &&
        errors.push("Invalid/Invalido");
      !this.$v.form.checkin.driverPhone.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    destinationErrors() {
      const errors = [];
      if (!this.$v.form.checkin.destination.$dirty) return errors;
      !this.$v.form.checkin.destination.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    carrierErrors() {
      const errors = [];
      if (!this.$v.form.checkin.carrierName.$dirty) return errors;
      !this.$v.form.checkin.carrierName.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    truckPlateErrors() {
      const errors = [];
      if (!this.$v.form.checkin.truckPlate.$dirty) return errors;
      !this.$v.form.checkin.truckPlate.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    trailerPlateErrors() {
      const errors = [];
      if (!this.$v.form.checkin.trailerPlate.$dirty) return errors;
      !this.$v.form.checkin.trailerPlate.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    clerkCheckInErrors() {
      const errors = [];
      if (!this.$v.form.clerk.checkIn.$dirty) return errors;
      !this.$v.form.clerk.checkIn.required && errors.push("Required/Requerido");
      return errors;
    },
    doorAssignmentErrors() {
      const errors = [];
      if (!this.$v.form.clerk.doorAssignment.$dirty) return errors;
      !this.$v.form.clerk.doorAssignment.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    doorErrors() {
      const errors = [];
      if (!this.$v.form.clerk.doorID.$dirty) return errors;
      !this.$v.form.clerk.doorID.required && errors.push("Required/Requerido");
      return errors;
    },
    forkliftDriverErrors() {
      const errors = [];
      if (!this.$v.form.clerk.forkLiftDriver.$dirty) return errors;
      !this.$v.form.clerk.forkLiftDriver.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.form.clerk.productID.$dirty) return errors;
      !this.$v.form.clerk.productID.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    startForkLiftErrors() {
      const errors = [];
      if (!this.$v.form.forkLift.startForkLift.$dirty) return errors;
      !this.$v.form.forkLift.startForkLift.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    finishForkLiftErrors() {
      const errors = [];
      if (!this.$v.form.forkLift.finishForkLift.$dirty) return errors;
      !this.$v.form.forkLift.finishForkLift.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.form.checkin.idCompany.$dirty) return errors;
      !this.$v.form.checkin.idCompany.required &&
        errors.push("Required/Requerido");
      return errors;
    },
  },
};
</script>

<style scoped>
.col {
  padding: 2px;
}

.col-12 {
  padding: 0px;
}

.license {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.page-title {
  padding-bottom: 0;
  margin-bottom: 0;
}

.page-info {
  padding-top: 0;
  margin-top: 0;
}

.v-form > .container > .layout > .flex {
  padding-bottom: 0 !important;
}

.input-date {
  top: 10px !important;
}

.signature {
  height: 11em;
}

#formCheckin .label-left {
  font-size: 1.2rem;
}
</style>
